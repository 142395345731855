export const OFFERS_CONFIG = {
    "en-1fsdff0": {
        "coupon": "5gmHRk5o",
        "currency": "usd",
        "planName": "starter_yearly_oct24_s"
    },
    "en-1fsdff1": {
        "coupon": "ThGJAe6g",
        "currency": "usd",
        "planName": "pro_yearly_oct24_s"
    },
    "en-1fsdff2": {
        "coupon": "lAwnIiet",
        "currency": "usd",
        "planName": "max_yearly_oct24_s"
    },
    "JBfceur0": {
        "coupon": "5gmHRk5o",
        "currency": "eur",
        "planName": "starter_yearly_oct24_s"
    },
    "JBfceur1": {
        "coupon": "ThGJAe6g",
        "currency": "eur",
        "planName": "pro_yearly_oct24_s"
    },
    "JBfceur2": {
        "coupon": "lAwnIiet",
        "currency": "eur",
        "planName": "max_yearly_oct24_s"
    },
    "JBfcgbp0": {
        "coupon": "5gmHRk5o",
        "currency": "gbp",
        "planName": "starter_yearly_oct24_s"
    },
    "JBfcgbp1": {
        "coupon": "ThGJAe6g",
        "currency": "gbp",
        "planName": "pro_yearly_oct24_s"
    },
    "JBfcgbp2": {
        "coupon": "lAwnIiet",
        "currency": "gbp",
        "planName": "max_yearly_oct24_s"
    },
    "au-4m3b6q70": {
        "coupon": "5gmHRk5o",
        "currency": "aud",
        "planName": "starter_yearly_oct24_s"
    },
    "au-4m3b6q71": {
        "coupon": "ThGJAe6g",
        "currency": "aud",
        "planName": "pro_yearly_oct24_s"
    },
    "au-4m3b6q72": {
        "coupon": "lAwnIiet",
        "currency": "aud",
        "planName": "max_yearly_oct24_s"
    },
    "ca-8p1t5k90": {
        "coupon": "5gmHRk5o",
        "currency": "cad",
        "planName": "starter_yearly_oct24_s"
    },
    "ca-8p1t5k91": {
        "coupon": "ThGJAe6g",
        "currency": "cad",
        "planName": "pro_yearly_oct24_s"
    },
    "ca-8p1t5k92": {
        "coupon": "lAwnIiet",
        "currency": "cad",
        "planName": "max_yearly_oct24_s"
    },
    "he-olm1950": {
        "coupon": "5gmHRk5o",
        "currency": "ils",
        "planName": "starter_yearly_oct24_s"
    },
    "he-olm1951": {
        "coupon": "ThGJAe6g",
        "currency": "ils",
        "planName": "pro_yearly_oct24_s"
    },
    "he-olm1952": {
        "coupon": "lAwnIiet",
        "currency": "ils",
        "planName": "max_yearly_oct24_s"
    },
    "br-7qa67l0": {
        "coupon": "5gmHRk5o",
        "currency": "brl",
        "planName": "starter_yearly_oct24_s"
    },
    "br-7qa67l1": {
        "coupon": "ThGJAe6g",
        "currency": "brl",
        "planName": "pro_yearly_oct24_s"
    },
    "br-7qa67l2": {
        "coupon": "lAwnIiet",
        "currency": "brl",
        "planName": "max_yearly_oct24_s"
    },
    "sw-7vj67l0": {
        "coupon": "5gmHRk5o",
        "currency": "chf",
        "planName": "starter_yearly_oct24_s"
    },
    "sw-7vj67l1": {
        "coupon": "ThGJAe6g",
        "currency": "chf",
        "planName": "pro_yearly_oct24_s"
    },
    "sw-7vj67l2": {
        "coupon": "lAwnIiet",
        "currency": "chf",
        "planName": "max_yearly_oct24_s"
    },
    "BF STARTER 50%": {},
    "5tSPX0sO": {
        "coupon": "S78Teukq",
        "currency": "usd",
        "planName": "starter_yearly_oct24_s"
    },
    "fh5neRfI": {
        "coupon": "S78Teukq",
        "currency": "aed",
        "planName": "starter_yearly_oct24_s"
    },
    "cjfAVOA0": {
        "coupon": "S78Teukq",
        "currency": "aud",
        "planName": "starter_yearly_oct24_s"
    },
    "WVpLuI2F": {
        "coupon": "S78Teukq",
        "currency": "brl",
        "planName": "starter_yearly_oct24_s"
    },
    "IXCoxo3o": {
        "coupon": "S78Teukq",
        "currency": "cad",
        "planName": "starter_yearly_oct24_s"
    },
    "8zgXfLGB": {
        "coupon": "S78Teukq",
        "currency": "clp",
        "planName": "starter_yearly_oct24_s"
    },
    "RtzgFGxC": {
        "coupon": "S78Teukq",
        "currency": "cop",
        "planName": "starter_yearly_oct24_s"
    },
    "afBnSuyp": {
        "coupon": "S78Teukq",
        "currency": "eur",
        "planName": "starter_yearly_oct24_s"
    },
    "9kM6k5yW": {
        "coupon": "S78Teukq",
        "currency": "gbp",
        "planName": "starter_yearly_oct24_s"
    },
    "RrY3dL8G": {
        "coupon": "S78Teukq",
        "currency": "idr",
        "planName": "starter_yearly_oct24_s"
    },
    "EorMJ66s": {
        "coupon": "S78Teukq",
        "currency": "ils",
        "planName": "starter_yearly_oct24_s"
    },
    "GPcAWLgP": {
        "coupon": "S78Teukq",
        "currency": "inr",
        "planName": "starter_yearly_oct24_s"
    },
    "Ak4Qd8cM": {
        "coupon": "S78Teukq",
        "currency": "jpy",
        "planName": "starter_yearly_oct24_s"
    },
    "P5EG6mAL": {
        "coupon": "S78Teukq",
        "currency": "krw",
        "planName": "starter_yearly_oct24_s"
    },
    "1rdoapg7": {
        "coupon": "S78Teukq",
        "currency": "mxn",
        "planName": "starter_yearly_oct24_s"
    },
    "Ug3SNSu3": {
        "coupon": "S78Teukq",
        "currency": "myr",
        "planName": "starter_yearly_oct24_s"
    },
    "TgKec5lU": {
        "coupon": "S78Teukq",
        "currency": "pen",
        "planName": "starter_yearly_oct24_s"
    },
    "yMG3p8cr": {
        "coupon": "S78Teukq",
        "currency": "php",
        "planName": "starter_yearly_oct24_s"
    },
    "DLurellF": {
        "coupon": "S78Teukq",
        "currency": "qar",
        "planName": "starter_yearly_oct24_s"
    },
    "AepcXQEt": {
        "coupon": "S78Teukq",
        "currency": "sar",
        "planName": "starter_yearly_oct24_s"
    },
    "rk8Qdtt8": {
        "coupon": "S78Teukq",
        "currency": "try",
        "planName": "starter_yearly_oct24_s"
    },
    "6SiXeJpM": {
        "coupon": "S78Teukq",
        "currency": "uah",
        "planName": "starter_yearly_oct24_s"
    },
    "6SiSeWpV": {
        "coupon": "S78Teukq",
        "currency": "chf",
        "planName": "starter_yearly_oct24_s"
    },
    "BF PRO 50%": {},
    "DKfSRRY9": {
        "coupon": "S78Teukq",
        "currency": "usd",
        "planName": "pro_yearly_oct24_s"
    },
    "dhNcKvBw": {
        "coupon": "S78Teukq",
        "currency": "aed",
        "planName": "pro_yearly_oct24_s"
    },
    "kfAAsanx": {
        "coupon": "S78Teukq",
        "currency": "aud",
        "planName": "pro_yearly_oct24_s"
    },
    "xuA4nI5r": {
        "coupon": "S78Teukq",
        "currency": "brl",
        "planName": "pro_yearly_oct24_s"
    },
    "jViJiZD4": {
        "coupon": "S78Teukq",
        "currency": "cad",
        "planName": "pro_yearly_oct24_s"
    },
    "S8EfK6IP": {
        "coupon": "S78Teukq",
        "currency": "clp",
        "planName": "pro_yearly_oct24_s"
    },
    "lg5gzTL8": {
        "coupon": "S78Teukq",
        "currency": "cop",
        "planName": "pro_yearly_oct24_s"
    },
    "beePAcDZ": {
        "coupon": "S78Teukq",
        "currency": "eur",
        "planName": "pro_yearly_oct24_s"
    },
    "7oUbPKlS": {
        "coupon": "S78Teukq",
        "currency": "gbp",
        "planName": "pro_yearly_oct24_s"
    },
    "MC8srcZD": {
        "coupon": "S78Teukq",
        "currency": "idr",
        "planName": "pro_yearly_oct24_s"
    },
    "92AG7uf5": {
        "coupon": "S78Teukq",
        "currency": "ils",
        "planName": "pro_yearly_oct24_s"
    },
    "nnl5qrFi": {
        "coupon": "S78Teukq",
        "currency": "inr",
        "planName": "pro_yearly_oct24_s"
    },
    "hjZWkFlt": {
        "coupon": "S78Teukq",
        "currency": "jpy",
        "planName": "pro_yearly_oct24_s"
    },
    "d4VO3iGn": {
        "coupon": "S78Teukq",
        "currency": "krw",
        "planName": "pro_yearly_oct24_s"
    },
    "6AB8cgeC": {
        "coupon": "S78Teukq",
        "currency": "mxn",
        "planName": "pro_yearly_oct24_s"
    },
    "al7eQwYg": {
        "coupon": "S78Teukq",
        "currency": "myr",
        "planName": "pro_yearly_oct24_s"
    },
    "kOt9an3t": {
        "coupon": "S78Teukq",
        "currency": "pen",
        "planName": "pro_yearly_oct24_s"
    },
    "JXqcCST4": {
        "coupon": "S78Teukq",
        "currency": "php",
        "planName": "pro_yearly_oct24_s"
    },
    "hN9h93Vk": {
        "coupon": "S78Teukq",
        "currency": "qar",
        "planName": "pro_yearly_oct24_s"
    },
    "91Ogczx2": {
        "coupon": "S78Teukq",
        "currency": "sar",
        "planName": "pro_yearly_oct24_s"
    },
    "EYr9gdmO": {
        "coupon": "S78Teukq",
        "currency": "try",
        "planName": "pro_yearly_oct24_s"
    },
    "sHV5pJXi": {
        "coupon": "S78Teukq",
        "currency": "uah",
        "planName": "pro_yearly_oct24_s"
    },
    "sWV5pSVi": {
        "coupon": "S78Teukq",
        "currency": "chf",
        "planName": "pro_yearly_oct24_s"
    },
    "av1": {
        "mode": "payment",
        "currency": "auto",
        "oneTimePurchases": [
            {
                "itemId": "avatar_single_nov24_s",
                "quantity": 1
            }
        ]
    },
    "av1-op": {
        "mode": "payment",
        "currency": "auto",
        "oneTimePurchases": [
            {
                "itemId": "avatar_single_s",
                "quantity": 1
            }
        ]
    },
    "av10m": {
        "mode": "payment",
        "currency": "auto",
        "oneTimePurchases": [
            {
                "itemId": "avatar_time10_nov24_s",
                "quantity": 1
            }
        ]
    },
    "pivos": {
        "currency": "auto",
        "planName": "starter_yearly_oct24_s"
    },
    "pivop": {
        "currency": "auto",
        "planName": "pro_yearly_oct24_s"
    },
    "T3AmD1sC": {
        "coupon": "vbl6LJIE",
        "currency": "auto",
        "planName": "max_yearly_oct24_s",
        "tier": 3
    }
}

/**
 * Extra event data for the current campaign
 */
export const currentCampaignEventData = {}